import React from 'react'
import {DASHBOARD_ROUTE, SCANS_ROUTE, SETTINGS_ROUTE, TENANTS_ROUTE, TENANT_SETTINGS_ROUTES, TENANT_VIEW_ROUTE, QUICK_SIZING_ROUTE} from './path'
import Dashboard from 'views/dashboard'
import {Tenants} from 'views/tenant'
import {Scan} from 'views/scan'
import {TenantSettings} from 'views/tenant/TenantSettings'
import {UserSettings} from 'pages/Dashboard/Accounts/UserSetting'
import {QuickSizing} from "../views/quicksizing";

const dashboardRoutes = [
    {
        path: DASHBOARD_ROUTE,
        element: React.createElement(Dashboard),
        restricted: true,
        showNav: true,
    },
    {
        path: TENANTS_ROUTE,
        element: React.createElement(Tenants),
        restricted: true,
        showNav: true,
    },
    {
        path: TENANT_VIEW_ROUTE(':id'),
        element: React.createElement(TenantSettings),
        restricted: true,
        showNav: true,
    },
    {
        path: TENANT_SETTINGS_ROUTES(':id'),
        element: React.createElement(TenantSettings),
        restricted: true,
        showNav: true,
    },
    {
        path: SCANS_ROUTE,
        element: React.createElement(Scan),
        restricted: true,
        showNav: true,
    },
    {
        path: QUICK_SIZING_ROUTE,
        element: React.createElement(QuickSizing),
        restricted: true,
        showNav: true,
    },
    {
        path: SETTINGS_ROUTE,
        element: React.createElement(UserSettings),
        restricted: true,
        showNav: true,
    },
]

export default dashboardRoutes
