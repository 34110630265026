/*
 * Author: dizhong zhu
 * Date: 06/08/2024
 */

import {useEffect, useState} from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import {TenantSideBar} from 'widgets/TenantSideBar'
import {useAppDispatch, useAppSelector} from 'store'
import {QuickSizeDisplayEntry} from "./DisplayArea/QuickSizeEntry";
import {delQuickSize, getQuickSize, QuickSizeT} from "store/quicksize";
import PageLoader from "components/PageLoader";


export const QuickSizing = () => {
    const dispatch = useAppDispatch()

    const [currentDomain, setCurrentDomain] = useState('')
    const [currentItems, setCurrentItems] = useState<QuickSizeT[]>([])

    const {tenants} = useAppSelector((state) => state.tenant)
    const {quickSizeItems, isQuickSizeListLoading} = useAppSelector((state) => state.quickSize)

    useEffect(() => {
        dispatch(getQuickSize())
    }, [])

    useEffect(() => {
        const filterItems = filterItemByDomain(currentDomain)
        setCurrentItems(filterItems)
    }, [quickSizeItems, currentDomain])

    const filterItemByDomain = (domain: string) => {
        if (domain === '') {
            return quickSizeItems
        }
        const filteredScans = quickSizeItems.filter((item: any) => item.domain === domain)
        return filteredScans.map((scan, index) => ({...scan, ID: index}))
    }

    const deleteQuickSizeItems = async (items: QuickSizeT[]) => {

        console.log(items)
        await dispatch(delQuickSize(items))
        dispatch(getQuickSize())
    }

    const handleTenantClick = async (domain: string) => {
        setCurrentDomain(domain)
    }

    return (
        <PageLoader open={isQuickSizeListLoading}>
            <Container fluid className='h-100'>
                <Row className='h-100'>
                    {/* The col show tenants and clickable*/}
                    <Col md={2} className='d-flex flex-column h-100 page-height'>
                        <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick}/>
                        <div className='d-flex justify-content-center my-2 w-full'>
                            <Button className='bg-blue-600 w-full' onClick={() => setCurrentDomain('')}>
                                Show All
                            </Button>
                        </div>
                    </Col>
                    <Col md={10} className='h-100'>
                        <QuickSizeDisplayEntry quickSizeItems={currentItems}
                                               deleteQuickSizeItems={deleteQuickSizeItems}/>
                    </Col>
                </Row>
            </Container>
        </PageLoader>
    )
}