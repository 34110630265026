/*
 * Author: dizhong zhu
 * Date: 08/08/2024
 */

import {Component, createRef, RefObject} from 'react';
import {
    getSelectedState,
    Grid, GridCellProps,
    GridColumn as Column,
    GridToolbar, GridDataStateChangeEvent, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent
} from '@progress/kendo-react-grid';
import {process, State, CompositeFilterDescriptor, filterBy, DataResult} from '@progress/kendo-data-query';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {DebouncedInput} from 'components/DebouncedInput'
import {getter} from "@progress/kendo-react-common";
import {QuickSizeT} from "store/quicksize"; // assuming you have a DebouncedInput component


interface props {
    quickSizeItems: QuickSizeT[];
    handleDelete: (selectedRows: QuickSizeT[]) => Promise<void>;
}

interface state {
    quickSizeData: QuickSizeT[];
    dataState: State;
    selectedState: { [id: string]: boolean | number[] };
}

const DATA_ITEM_KEY = "uuid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

export class QuickSizeDisplayTable extends Component<props, state> {
    // private _export = createRef<ExcelExport | null>();

    private readonly _export: RefObject<ExcelExport>;
    private columns: any[]; // Declare columns as a class property

    constructor(props: props) {
        super(props);

        const {quickSizeItems} = props

        const initialDataState: State = {
            take: 20,
            skip: 0,
            filter: undefined,
            sort: [{field: "time_stamp", dir: "desc"}]
        };

        this.state = {
            quickSizeData: quickSizeItems,
            dataState: initialDataState,
            selectedState: {}
        };

        this.columns = [
            {
                field: SELECTED_FIELD, width: 45,
                // headerSelectionValue: this.state.scanData.findIndex((item) => !this.state.selectedState[idGetter(item)]) === -1,
                sortable: false,
                filterable: false
            },
            {
                field: "uuid", title: "UUID",
                width: 370,
            },
            {field: "domain", title: "Domain", width: 200},
            {field: "gender", title: "Gender"},
            {field: "age", title: "Age"},
            {field: "weight", title: "Weight"},
            {field: "height", title: "Height"},
            {field: "pant_size", title: "Pant Size"},
            {field: "jacket_size", title: "Jacket Size"},
            {field: "shoe_size", title: "Shoe Size"},
            {field: "bra_size", title: "Bra Size"},
            {field: "size", title: "Recommend Size"},
            {field: "email", title: "Email"},
            {
                field: "time_stamp", title: "Time Stamp", width: 180,
                cell: (props: GridCellProps) => {
                    const field = props.field as keyof QuickSizeT;
                    const dateValue = props.dataItem[field];
                    const formattedDate = dateValue ? new Date(dateValue).toLocaleString() : "";
                    return <td>{formattedDate}</td>;
                }
            },
            {
                field: "complete", title: "Complete", filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || "";
                    return (
                        <td>
                            <input
                                type="checkbox"
                                checked={props.dataItem[field]}
                                readOnly={true}
                            />
                        </td>
                    );
                }
            },
            {field: "failed_reason", title: "Failed Reason"},
            {field: "order_id", title: "Order Id"},
            {
                field: "return", title: "Return", filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || "";
                    return (
                        <td>
                            <input
                                type="checkbox"
                                checked={props.dataItem[field]}
                                readOnly={true}
                            />
                        </td>
                    );
                }
            },
            {field: "return_reason", title: "Return Reason"},
        ];

        this._export = createRef<ExcelExport>();
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.quickSizeItems !== this.props.quickSizeItems) {
            this.setState({quickSizeData: this.props.quickSizeItems, selectedState: {}});
        }
    }

    excelExport = () => {
        if (this._export.current !== null) {
            this._export.current.save();
        }
    };

    handleDataStateChange = (event: GridDataStateChangeEvent) => {
        this.setState({dataState: event.dataState});
    };

    onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: this.state.selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        this.setState({selectedState: newSelectedState});
    };

    onHeaderSelectionChange = (event: GridHeaderSelectionChangeEvent) => {
        const checkboxElement: any = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {...this.state.selectedState};

        this.state.quickSizeData.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        this.setState({selectedState: newSelectedState});
    };

    filterData = (value: any) => {
        const filter: CompositeFilterDescriptor = {
            logic: "or",
            filters: this.columns
                .filter(column => column.field && column.filterable !== false)
                .map(column => ({
                    field: column.field,
                    operator: "contains",
                    value: value
                }))
        };
        this.setState({quickSizeData: filterBy(this.props.quickSizeItems, filter)});
    };


    render() {
        const {quickSizeData, dataState, selectedState} = this.state
        const {handleDelete} = this.props
        return (
            <>
                <ExcelExport
                    fileName="scans.xlsx"
                    data={quickSizeData}
                    ref={this._export}>
                    {this.columns.filter(column => column.field !== SELECTED_FIELD).map((col, idx) => (
                        <ExcelExportColumn key={idx} field={col.field} title={col.title}/>
                    ))}
                </ExcelExport>
                <Grid
                    className="nowrap-grid"
                    data={{
                        ...(process(quickSizeData, dataState) as DataResult),
                        data: process(quickSizeData, dataState).data.map((item: QuickSizeT) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        })),
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    onSelectionChange={this.onSelectionChange}
                    onHeaderSelectionChange={this.onHeaderSelectionChange}
                    pageable={{
                        type: "input",
                        pageSizes: [5, 10, 15, 20, 30],
                        previousNext: true,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    total={quickSizeData.length}
                    sortable={true}
                    sort={dataState.sort}
                    onDataStateChange={this.handleDataStateChange}
                >
                    <GridToolbar>
                        <DebouncedInput
                            value={""}
                            onChange={value => this.filterData(value)}
                            className="p-2 font-lg shadow border border-block"
                            placeholder="Search all columns..."
                        />
                        <OverlayTrigger
                            placement='bottom'
                            delay={{show: 250, hide: 200}}
                            overlay={<Tooltip style={{position: 'fixed'}}>Export all data to Excel</Tooltip>}>
                            <Button className='bg-blue-600'
                                    onClick={this.excelExport}>
                                Export to Excel
                            </Button>
                        </OverlayTrigger>
                        <Button className='bg-blue-600'
                                disabled={Object.values(selectedState).filter((selected) => selected === true).length === 0}
                                onClick={(e) => {
                                    if (window.confirm("Are you sure you want to delete the selected items?")) {
                                        const selectedItems = Object.entries(selectedState)
                                            .map(([key, value]) => quickSizeData.find((item) => item.uuid === key && value === true))
                                            .filter((item): item is QuickSizeT => item !== undefined);
                                        handleDelete(selectedItems);
                                    }
                                }}
                        >
                            Delete: {Object.values(selectedState).filter((selected) => selected === true).length}
                        </Button>
                    </GridToolbar>
                    {this.columns.map((col, idx) => (
                        <Column key={idx} {...col} />
                    ))}
                </Grid>
            </>
        );
    }
}
