/*
 * Author: dizhong zhu
 * Date: 20/03/2023
 */

import {useEffect, useState} from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import {TenantSideBar} from 'widgets/TenantSideBar'
import {ScanDispEntry} from './DispArea/ScanDispEntry'
import {useAppDispatch, useAppSelector} from 'store'
import {delScans, getScanDownload, getScans, ScanT} from 'store/scan'
import PageLoader from "components/PageLoader";


export const Scan = () => {
    const dispatch = useAppDispatch()

    const [currentScans, setCurrentScans] = useState<ScanT[]>([])
    const [currentDomain, setCurrentDomain] = useState('')


    const {tenants} = useAppSelector((state) => state.tenant)
    const {scans, isScanListLoading} = useAppSelector((state) => state.scan)

    const filterScanByDomain = (domain: string) => {
        if (domain === '') {
            return scans
        }

        const filteredScans = scans.filter((scan: any) => scan.domain === domain)
        return filteredScans.map((scan, index) => ({...scan, ID: index}))
    }

    useEffect(() => {
        // dispatch(getTenants())
        dispatch(getScans())
    }, [])

    useEffect(() => {
        const filter_scans = filterScanByDomain(currentDomain)
        setCurrentScans(filter_scans)
    }, [scans, currentDomain])

    const handleTenantClick = async (domain: string) => {
        setCurrentDomain(domain)
    }

    const getScanURL = async (scan: ScanT[]) => {
        return await dispatch(getScanDownload(scan)).unwrap()
    }

    const deleteScans = async (scan: ScanT[]) => {
        await dispatch(delScans(scan))
        await dispatch(getScans())
    }

    return (
        <PageLoader open={isScanListLoading}>
            <Container fluid className='h-100'>
                <Row className='h-100'>
                    {/* The col show tenants and clickable*/}
                    <Col md={2} className='d-flex flex-column h-100 page-height'>
                        <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick}/>
                        <div className='d-flex justify-content-center my-2 w-full'>
                            <Button className='bg-blue-600 w-full' onClick={() => setCurrentDomain('')}>
                                Show All
                            </Button>
                        </div>
                    </Col>
                    <Col md={10} className='h-100'>
                        <ScanDispEntry scans={currentScans} getScanURL={getScanURL} deleteScans={deleteScans}/>
                    </Col>
                </Row>
            </Container>
        </PageLoader>
    )
}
