/*
 * Author: dizhong zhu
 * Date: 09/03/2023
 */

import React, {useState} from 'react'
import {Form, InputGroup, Button} from 'react-bootstrap'
import {Eye, EyeSlash} from 'react-bootstrap-icons'

interface FormInputProps {
    name: string;
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type: string;
    placeholder?: string;
}

const FormInput: React.FC<FormInputProps> = ({name, label, value, onChange, type, placeholder}) => {
    return (
        <React.Fragment>
            <div className={'form-group p-2'}>
                <label className='form-label' htmlFor={name}>
                    {label}
                </label>
                <input className={'form-control'} name={name} id={name} placeholder={placeholder} type={type} value={value} onChange={onChange}/>
            </div>
        </React.Fragment>
    )
}

interface FormInputAreaProps {
    name: string;
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    rows: number;
}

const FormInputArea: React.FC<FormInputAreaProps> = ({name, label, value, onChange, rows}) => {
    return (
        <React.Fragment>
            <div className={'form-group p-2'}>
                <label className='form-label' htmlFor={name}>
                    {label}
                </label>
                <textarea className={'form-control'} name={name} id={name} value={value} rows={rows} onChange={onChange}/>
            </div>
        </React.Fragment>
    )
}

interface PasswordInputProps {
    controlId: string;
    label: string;
    value: string;
    readOnly?: boolean;
    className?: string;
    helpText?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({controlId, label, value, readOnly, className, helpText}) => {
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <Form.Group className='mb-3' controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <InputGroup>
                <Form.Control type={showPassword ? 'text' : 'password'} value={value} className={className} readOnly={readOnly}/>
                <Button variant='outline-secondary' onClick={toggleShowPassword}>
                    {showPassword ? <EyeSlash/> : <Eye/>}
                </Button>
            </InputGroup>
            {helpText && <Form.Text className='text-muted'>{helpText}</Form.Text>}
        </Form.Group>
    )
}

export {FormInput, FormInputArea, PasswordInput}
