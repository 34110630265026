export const BASE_ROUTE = '/'

// Auth
export const REGISTER_ROUTE = '/register'
export const LOGIN_ROUTE = '/login'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RESET_PASSWORD_ROUTE = '/reset-password'

// Dashboard
export const DASHBOARD_ROUTE = '/dashboard'
export const TENANTS_ROUTE = '/tenants'
export const TENANT_VIEW_ROUTE = (id: string) => `/tenants/${id}`
export const TENANT_SETTINGS_ROUTES = (id: string) => `/tenants/${id}/*`
export const SCANS_ROUTE = '/scans'
export const QUICK_SIZING_ROUTE = '/quick-sizing'
export const SETTINGS_ROUTE = '/settings'

// Errors
export const ERROR_404_ROUTE = '/error/404'
