/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

import {ListGroup} from 'react-bootstrap'
import React from 'react'

interface ItemT {
    name: string;
}

interface props {
    items: ItemT[];
    currentItem: string;
    OnClick: (item: ItemT) => void;
}

const TenSettingSideBar: React.FC<props> = ({items, currentItem, OnClick}) => {
    return (
        <ListGroup>
            {items.map((item, index) => (
                <ListGroup.Item key={index} action active={item.name === currentItem} onClick={() => OnClick(item)}>
                    {item.name}
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}

export {TenSettingSideBar}
