import http from './http'

export async function getTransactionStatsRequest(domain: string) {
    return (await http.get(`/dashboard/analytic/transaction-stat`,
        {headers: {DOMAIN: domain}})).data
}

export async function getVisitorStatsRequest(domain: string) {
    return (await http.get(`/dashboard/analytic/page-view?granularity=minutes`,
        {headers: {DOMAIN: domain}})).data
}

export async function getTransactionRequest(domain: string) {
    return (await http.get(`/dashboard/analytic/transaction`,
        {headers: {DOMAIN: domain}})).data
}