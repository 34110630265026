import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getTenantRequest, getTenantsRequest} from 'services/tenant'
import {RootState} from 'store'
import {isBefore, subMonths} from "date-fns";

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/
export interface TenantT {
    name: string
    description: string
    domain: string
    apikey: string
    plan: string
    account_email: string
    last_activity: string
    defaults: TenantDefault
}

interface TenantState {
    tenants: TenantT[]
    activeTenant: TenantT | null
    isLoadingTenants: boolean
    tenantsError: any[]
}


interface TenantDefault {
    gender: string
}

export interface ListTenantResponse {
    data: TenantT[]
    error: any[]
    message: string
    status: boolean
}

export interface ViewTenantResponse {
    data: TenantT
    error: any[]
    message: string
    status: boolean
}


export const initialState: TenantState = {
    tenants: [],
    activeTenant: null,
    isLoadingTenants: true,
    tenantsError: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getTenants = createAsyncThunk<TenantT[]>('tenant/list', async (_,{dispatch,getState}) => {
    dispatch(isLoadingTenants(true))

    return getTenantsRequest()
        .then((res: ListTenantResponse) => {
            dispatch(setTenants([]))

            const state = getState() as RootState; // Access the entire state
            const showActiveTenants = state.settings.showActiveTenants; // Get showActiveTenants from the 'setting' slice


            let tenantsData = res.data
            if (res.data) {
                // Filter tenants based on the showActiveTenants parameter
                if (showActiveTenants) {
                    const oneMonthAgo = subMonths(new Date(), 1);
                    tenantsData = tenantsData.filter(tenant =>
                        !isBefore(new Date(tenant.last_activity), oneMonthAgo)
                    );
                }

                const sortData = tenantsData.sort((a, b) => a.name.localeCompare(b.name))
                dispatch(setTenants(sortData))
            }
            return tenantsData
        })
        .catch((err: any) => {
            dispatch(setTenantsError([err.response.data.message]))
        })
        .finally(() => dispatch(isLoadingTenants(false))) as Promise<TenantT[]>
})

export const getTenant = createAsyncThunk<TenantT, string>('tenant/view', async (domain: string, {dispatch}) => {
    dispatch(isLoadingTenants(true))

    return getTenantRequest(domain)
        .then((res: ViewTenantResponse) => {
            dispatch(setActiveTenant(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setTenantsError([err.response.data.message]))
        })
        .finally(() => dispatch(isLoadingTenants(false))) as Promise<TenantT>
})

export const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        setTenants: (state, {payload}: PayloadAction<TenantT[]>) => {
            state.tenants = payload
        },
        setActiveTenant: (state, {payload}: PayloadAction<TenantT>) => {
            state.activeTenant = payload
        },
        setTenantsError: (state, {payload}: PayloadAction<any>) => {
            state.tenantsError = payload
        },

        isLoadingTenants: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoadingTenants = payload
        },
    },
})

export const {setTenants, setActiveTenant, isLoadingTenants, setTenantsError} = tenantSlice.actions
export default tenantSlice.reducer
