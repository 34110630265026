/*
 * Author: dizhong zhu
 * Date: 14/08/2024
 *
 * This file maintains the setting for the whole session, not saving anything in cookie or database yet, will do in future
 * Only assume you do that without refresh the page. If you refresh the page, the setting will be lost
 */


import {createSlice, PayloadAction} from "@reduxjs/toolkit";

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

interface SettingState {
    showActiveTenants: boolean;
}

export const initialState: SettingState = {
    showActiveTenants: false
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/
export const SettingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setShowActiveTenants: (state, {payload}: PayloadAction<boolean>) => {
            state.showActiveTenants = payload
        }
    },
})

export const {setShowActiveTenants} = SettingSlice.actions
export default SettingSlice.reducer