/*
 * Author: Muhammad Furqan Alam
 * Date: 07/09/2024
 */

// import {Chart} from 'react-chartjs-2';
import {useMemo, useRef, useState} from 'react'
import {Button, Table, OverlayTrigger, Tooltip} from 'react-bootstrap'

import {useReactTable, getCoreRowModel, getSortedRowModel, flexRender, getFilteredRowModel, getPaginationRowModel} from '@tanstack/react-table'
import {DebouncedInput} from 'components/DebouncedInput'
import FileSaver from 'file-saver'
import {IndeterminateCheckbox} from 'components/IndeterminateCheckbox'


function Analytics({views, transaction, loadingTransaction}: any) {
    const isCancelled = useRef(false)


    const [isDownloading, setIsDownloading] = useState(false)

    const handleDownload = async (isSelectedDownloads = false, transaction = []) => {
        isCancelled.current = false;
        setIsDownloading(true);

        const dataToDownload = isSelectedDownloads ? transaction : dataTransaction;

        try {
            const headers = Object.keys(dataToDownload[0]);

            let csv = headers.join(',') + '\n';

            csv += dataToDownload.map((row: any) =>
                headers.map(fieldName =>
                    `"${row[fieldName] ?? ''}"`
                ).join(',')
            ).join('\n');


            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});


            FileSaver.saveAs(blob, 'transactions.csv');

            setIsDownloading(false);
        } catch (error) {
            console.error('Error downloading CSV:', error);
            setIsDownloading(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "order_number",
                header: "Order Number",
            },
            {
                accessorKey: "order_id",
                header: "Order Id",
            },
            {
                accessorKey: "scan_id",
                header: "Scan Id",
            },
            {
                accessorKey: "quick_size_id",
                header: "Quick Size Id",
            },
            {
                accessorKey: "return_id",
                header: "Return Id",
            },
            {
                accessorKey: "item_name",
                header: "Item Name",
            },
            {
                accessorKey: "price",
                header: "Price",
            },
            {
                accessorKey: "use_voucher",
                header: "User Voucher",
                cell: ({row}: any) => (
                    <div className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getValue("use_voucher"),
                                disabled: true,

                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),

            },
            {
                accessorKey: "return",
                header: "Return",
                cell: ({row}: any) => (
                    <div className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getValue("return"),
                                disabled: true,

                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),

            },
            {
                accessorKey: "use_aistetic",
                header: "Use Aistetic",
                cell: ({row}: any) => (

                    <div className="px-1">
                        <IndeterminateCheckbox

                            {...{
                                checked: row.getValue("use_aistetic"),
                                disabled: true,
                                selected: row.getValue("use_aistetic"),
                            }}
                        />
                    </div>
                ),
            },
            {
                accessorKey: "created_at",
                header: "Time",
                cell: ({getValue}: any) => {
                    return new Date(getValue()).toLocaleString()
                },
            },
            {
                accessorKey: "return_reason",
                header: "Return Reason",
            },

        ], [])

    const dataTransaction = useMemo(() => transaction, [transaction])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState([
        {
            id: 'time',
            desc: true,
        }
    ])
    const [rowSelection, setRowSelection] = useState({})

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    })
    const tableInstance = useReactTable({
        columns,
        data: dataTransaction,
        state: {
            sorting,
            globalFilter,
            pagination,
            rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,

        //   debugTable: true,
    })

    // const formatLabel = (index: number) => {
    //     switch (index) {
    //         case 0:
    //             return '1D';
    //         case 1:
    //             return '7D';
    //         case 2:
    //             return '30D';
    //         case 3:
    //             return '60D';
    //         case 4:
    //             return '180D';
    //         default:
    //             return '';
    //     }
    // };


    // const prepareData = (period: number) => {
    //
    //     const startDate = new Date();
    //     startDate.setDate(startDate.getDate() - period);
    //     const filteredData = views && views.filter((entry: any) => {
    //         const entryDate = new Date(entry.Period);
    //         return entryDate >= startDate && entryDate <= new Date();
    //     });
    //
    //     const uniqueUsers = filteredData.reduce((sum: number, entry: any) => sum + entry.UniqueUsers, 0);
    //     const totalViews = filteredData.reduce((sum: number, entry: any) => sum + entry.TotalViews, 0);
    //
    //     return {
    //         uniqueUsers,
    //         totalViews,
    //     };
    // };


    // const data = {
    //     labels: ['1D', '7D', '30D', '60D'], // Labels for x-axis
    //     datasets: [
    //         {
    //             label: 'Unique Users',
    //             data: views && [1, 3, 7, 30, 60].map((period) => prepareData(period).uniqueUsers), // UniqueUsers data for each period
    //             backgroundColor: 'rgba(54, 162, 235, 0.2)',
    //             borderColor: 'rgba(54, 162, 235, 1)',
    //             borderWidth: 1,
    //         },
    //         {
    //             label: 'Total Views',
    //             data: views && [1, 3, 7, 30, 60].map((period) => prepareData(period).totalViews), // TotalViews data for each period
    //             backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //             borderColor: 'rgba(255, 99, 132, 1)',
    //             borderWidth: 1,
    //         }
    //     ],
    // };
    //
    //
    // const options = {
    //     scales: {
    //         x: {
    //             ticks: {
    //                 callback: (value: any, index: any, values: any) => formatLabel(index), // Callback to format x-axis labels
    //             },
    //             title: {
    //                 display: true,
    //                 text: 'Time Period',
    //             },
    //             grid: {
    //                 display: false, // Disable x-axis grid lines
    //             },
    //         },
    //         y: {
    //             beginAtZero: true,
    //             title: {
    //                 display: true,
    //                 text: 'Visits',
    //             },
    //             grid: {
    //                 display: false, // Disable x-axis grid lines
    //             },
    //         },
    //     },
    // };


    // const headerClass = 'py-2 px-4 border-b border-gray-200 font-semibold uppercase text-gray-600 bg-gray-200'
    // const rowClass = ' px-4 border-b bg-zinc-50 border-gray-200'


    return (
        <div>
            {/*<div className='grid md:flex justify-around gap-4  mt-6'>*/}
            {/*    <div className='h-full w-fit'>*/}
            {/*        <p className='text-lg font-semibold mb-4'>Unique Visitors</p>*/}
            {/*        <div style={{height: '400px', width: '300%'}}> /!* Adjust height and width here *!/*/}

            {/*            {views && <Chart type={'line'} data={data} options={options}/>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className='my-2 flex justify-between'>
                <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(String(value))}
                    className="p-2 font-lg shadow border border-block"
                    placeholder="Search all columns..."/>
                <OverlayTrigger
                    placement='bottom'
                    delay={{show: 250, hide: 200}}
                    overlay={<Tooltip style={{position: 'fixed'}}>Export to Excel</Tooltip>}>
                    <Button className='bg-blue-600' disabled={isDownloading || tableInstance?.getRowCount() === 0} onClick={(e) => {

                        handleDownload(true, dataTransaction)

                    }}>
                        Export to Excel: {tableInstance.getRowCount()}
                    </Button>
                </OverlayTrigger>
            </div>
            <Table striped bordered hover>
                <thead>
                {tableInstance.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            className={
                                                header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : ''
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                            title={
                                                header.column.getCanSort()
                                                    ? header.column.getNextSortingOrder() === 'asc'
                                                        ? 'Sort ascending'
                                                        : header.column.getNextSortingOrder() === 'desc'
                                                            ? 'Sort descending'
                                                            : 'Clear sort'
                                                    : undefined
                                            }
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getSortIndex()] ?? null}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {tableInstance
                    .getRowModel().rows.map(row => {
                        return (
                            <tr key={row?.id}>

                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell?.id}>

                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div className="flex items-center gap-2">
                <button
                    className="border rounded-md px-3  text-blue-600 text-lg"
                    onClick={() => tableInstance.firstPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                >
                    {'«'}
                </button>
                <button
                    className="border rounded-md px-3  text-blue-600 text-lg"
                    onClick={() => tableInstance.previousPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                >
                    {'‹'}
                </button>
                <button
                    className="border rounded-md px-3  text-blue-600 text-lg"
                    onClick={() => tableInstance.nextPage()}
                    disabled={!tableInstance.getCanNextPage()}
                >
                    {'›'}
                </button>
                <button
                    className="border rounded-md px-3 text-blue-600 text-lg"
                    onClick={() => tableInstance.lastPage()}
                    disabled={!tableInstance.getCanNextPage()}
                >
                    {'»'}
                </button>
                <span className="flex items-center gap-1 text-sm">
          <div>Page</div>
          <strong>
            {tableInstance.getState().pagination.pageIndex + 1} of
              {tableInstance.getPageCount().toLocaleString()}
          </strong>
        </span>
                <span className="flex items-center gap-1 text-sm">
          | Go to page:
			<input
                type="number"
                min={1}
                max={tableInstance.getPageCount().toLocaleString()}
                defaultValue={tableInstance.getState().pagination.pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    tableInstance.setPageIndex(page)
                }}
                className="border px-1 py-1 rounded-md w-16 outline-none"
            />
        </span>
                <select
                    className="border px-1 py-1 rounded-md outline-none text-sm"
                    value={tableInstance.getState().pagination.pageSize}
                    onChange={e => {
                        tableInstance.setPageSize(Number(e.target.value))
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export {Analytics}
