/*
 * Author: dizhong zhu
 * Date: 08/08/2024
 */

import {Col, Container, Row} from 'react-bootstrap';
import React, {Component} from 'react';
import {QuickSizeDisplayTable} from "./QuickSizeTable";
import {QuickSizeT} from "store/quicksize";

import {TimeFilters} from "widgets/UtilWidgets";


interface props {
    quickSizeItems: QuickSizeT[];
    deleteQuickSizeItems: (item: QuickSizeT[]) => Promise<void>;
}

interface state {
    startDate: any;
    endDate: any;
    filterItems: QuickSizeT[];
}

export class QuickSizeDisplayEntry extends Component<props, state> {
    private isCancelled = false;

    constructor(props: props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            filterItems: props.quickSizeItems,
        };
    }

    componentDidUpdate(prevProps: props, prevState: state) {
        if (
            prevProps.quickSizeItems !== this.props.quickSizeItems ||
            prevState.startDate !== this.state.startDate ||
            prevState.endDate !== this.state.endDate
        ) {
            this.updateFilteredScans();
        }
    }

    updateFilteredScans = () => {
        const {quickSizeItems} = this.props;
        const {startDate, endDate} = this.state;
        let filterItems = quickSizeItems;

        if (startDate) {
            filterItems = filterItems.filter(item => new Date(item.time_stamp) >= startDate);
        }

        if (endDate) {
            filterItems = filterItems.filter(item => new Date(item.time_stamp) <= endDate);
        }

        this.setState({filterItems: filterItems});
    };

    handleStartDateChange = (date: any) => {
        this.setState({startDate: date});
    };

    handleEndDateChange = (date: any) => {
        this.setState({endDate: date});
    };


    render() {
        const {filterItems, startDate, endDate} = this.state;
        return (
            <React.Fragment>
                <Container fluid className='h-100 d-flex flex-column'>
                    <Row>
                        <Col md={8}>
                            <TimeFilters
                                startDate={startDate}
                                OnStartDateChange={this.handleStartDateChange}
                                endDate={endDate}
                                OnEndDateChange={this.handleEndDateChange}
                            />
                        </Col>
                    </Row>
                    <Row className='flex-grow-1 overflow-hidden'>
                        <Col className='h-100 overflow-auto' style={{paddingRight: 0, paddingLeft: 0}}>
                            <QuickSizeDisplayTable quickSizeItems={filterItems}
                                                   handleDelete={this.props.deleteQuickSizeItems}/>
                        </Col>
                    </Row>

                </Container>
            </React.Fragment>
        );
    }
}