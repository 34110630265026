/*
 * Author: dizhong zhu
 * Date: 21/03/2023
 */

import {Button, Card, Col, Container, Modal, Row, Table} from 'react-bootstrap'
import {useEffect} from 'react'
import '../ScanStyle.css'
import ScanObj from './ScanObj'
import {useAppDispatch, useAppSelector} from 'store'
import {getScanDetails, ScanT} from 'store/scan'
import Loader from 'components/Loader'
import Cookies from 'js-cookie'

interface props {
    show: boolean
    OnPrev: () => void
    OnNext: () => void
    OnHide: () => void
    scan: ScanT
}


function ScanDetailModal({show, OnPrev, OnNext, OnHide, scan}: props) {
    const dispatch = useAppDispatch()
    const {activeScan, isLoading} = useAppSelector((state) => state.scan)

    useEffect(() => {
        dispatch(getScanDetails({domain: scan?.domain, id: scan?.uuid}))
    }, [scan,show])


    return (
        <Modal dialogClassName='custom-modal' show={show} onHide={OnHide} contentClassName='max-h-full h-full'>
            <Modal.Header closeButton>
                <Modal.Title>Scan Detail. ID {scan?.uuid ?? ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='overflow-auto ' style={{overflowY: 'scroll'}}>
                <Container fluid style={{maxHeight: '80vh'}}>
                    {isLoading || !activeScan ? (
                        <Loader/>
                    ) : (
                        <Row>
                            <Col md={4} className='pb-2'>
                                <Card>
                                    <Card.Header>Measurements</Card.Header>
                                    <Card.Body>
                                        <Table className='overflow-auto' style={{overflowY: 'scroll'}}>
                                            <thead>
                                            <tr>
                                                <th>Body Part</th>
                                                <th>Measurements(cm)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {activeScan &&
                                                Object.entries(activeScan.measurements).map(([key, value]) => (
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>{Math.round(value * 2) / 2}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card>
                                    <Card.Header>User Information</Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <tbody>
                                            {activeScan &&
                                                Object.entries(activeScan.user_info.userInfo).map(([key, value]) => (
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>{value ?? ''}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                <Card className='my-4' style={{width: '100%', height: '70%'}}>
                                    <Card.Header>Display Image</Card.Header>
                                    <Card.Body className=''>
                                        <img
                                            alt=''
                                            className='mx-auto h-full'
                                            src={`${
                                                activeScan.first_image === null
                                                    ? 'https://www.absolutecoldroom.co.uk/cdn/shop/products/NoImageAvailable_0e7fb1c5-4780-4d20-a461-c803b9daffd4_large.png?v=1620042400'
                                                    : `data:image/jpg;base64,${activeScan.first_image}`
                                            }`}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card style={{width: '100%', height: '70%'}}>
                                    <Card.Header>3D Model</Card.Header>
                                    <Card.Body>
                                        <ScanObj obj_b64={activeScan.obj_64}/>

                                        <button type='button' className='bg-black  text-white py-1 px-3 text-lg' onClick={(e) => {
                                            const dataToSend = {
                                                "ID": scan?.uuid,
                                                "Token": localStorage.getItem('token'),
                                                "Domain": scan?.domain,
                                            }
                                            const domain = window.location.hostname?.split('.')?.slice(-2)?.join('.');
                                            Cookies.set('userInfo', JSON.stringify(dataToSend), {domain: `${domain ?? "aistetic.com"}`});
                                            window.open(`${process.env.REACT_APP_MEASUREMENTS_TOOL_URL}`, '_blank')
                                        }}>
                                            Measurements
                                        </button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button className='bg-gray-600' variant='secondary' onClick={OnPrev}>
                    Prev
                </Button>
                <Button className='bg-gray-600' variant='secondary' onClick={OnNext}>
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export {ScanDetailModal}
