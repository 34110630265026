import React, {useState} from 'react'
import {Form, Button} from 'react-bootstrap'
import '../tenants.css'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/webpack-resolver'
import {ModalDelete} from '../Modals'
import {useNavigate} from 'react-router-dom'
import {DisplaySuccess} from 'widgets/DisplayAlert'
import {TENANTS_ROUTE} from 'routes/path'

interface props {
    domain: string;
}

export const DangerousZone: React.FC<props> = ({domain}) => {
    const [showDelete, setShowDelete] = useState(false)

    const navigate = useNavigate()

    const BeforeDeleteTenant = async () => {
        setShowDelete(true)
    }

    const HandleHide = async () => {
        setShowDelete(false)
    }

    const HandleDeleteTenant = async () => {
        setShowDelete(false)
        navigate(`${TENANTS_ROUTE}`)
        DisplaySuccess(`Tenant ${domain} deleted successfully`)
    }

    return (
        <React.Fragment>
            <Form className='bold-form-labels'>
                <div className='dangerous-rounded-box d-flex justify-content-between align-items-center'>
                    <Form.Group>
                        <Form.Label>Delete this tenant</Form.Label>
                        <br/>
                        <Form.Text style={{color: 'red'}}>
                            All the scan data, configuration will be erased once you delete the tenant, and this is not reversible.
                        </Form.Text>
                    </Form.Group>
                    <Button className='bg-red-600' variant='danger' onClick={BeforeDeleteTenant}>
                        Delete Tenant
                    </Button>
                </div>
            </Form>
            <ModalDelete show={showDelete} domain={domain} onHide={HandleHide} onDelete={HandleDeleteTenant}/>
        </React.Fragment>
    )
}
