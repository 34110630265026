/*
 * Author: dizhong zhu
 * Date: 08/08/2024
 */

import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {delQuickSizeRequest, getQuickSizeListRequest} from "services/quicksize";
import {delScansRequest} from "../services/scan";
import {ScanT, setScanListIsLoading} from "./scan";

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/
export interface QuickSizeT {
    uuid: string
    domain: string;
    gender: string;
    age: string;
    weight: string;
    height: string;
    pant_size: string;
    jacket_size: string;
    shoe_size: string;
    bra_size: string;
    size: string;
    email: string;
    time_stamp: string;
    order_id: string;
}

interface QuickSizeResponse {
    data: any
    error: any[]
    message: string
    status: boolean
}

interface QuickSizeState {
    quickSizeItems: QuickSizeT[]
    isQuickSizeListLoading: boolean
    errors: any[]
}

export const initialState: QuickSizeState = {
    quickSizeItems: [],
    isQuickSizeListLoading: true,
    errors: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getQuickSize = createAsyncThunk<QuickSizeT[]>('quick-size/list', async (_, {dispatch}) => {
    dispatch(setQuickSizeListIsLoading(true))
    return getQuickSizeListRequest()
        .then((res: QuickSizeResponse) => {
            let itemList: QuickSizeT[] = []
            const quickSizeItems = res.data
            let index = 0
            for (const key in quickSizeItems) {
                const item_with_domains = quickSizeItems[key].map((item: QuickSizeT) => {
                    return {...item, ID: index++, domain: key}
                })
                itemList = [...itemList, ...item_with_domains]
            }
            dispatch(setQuickSizeItems(itemList))
            return itemList
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setQuickSizeListIsLoading(false))) as Promise<QuickSizeT[]>
})

export const delQuickSize = createAsyncThunk<void, QuickSizeT[]>('quick-size/delete', async (item: QuickSizeT[], {dispatch}) => {
    return delQuickSizeRequest(item)
        .then((res: QuickSizeResponse) => {
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
})

export const quickSizeSlice = createSlice({
    name: 'quick-size',
    initialState,
    reducers: {
        setQuickSizeItems: (state, {payload}: PayloadAction<QuickSizeT[]>) => {
            state.quickSizeItems = payload
        },
        setError: (state, {payload}: PayloadAction<any>) => {
            state.errors = payload
        },
        setQuickSizeListIsLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isQuickSizeListLoading = payload
        },
    },
})

export const {
    setQuickSizeItems,
    setQuickSizeListIsLoading,
    setError
} = quickSizeSlice.actions

export default quickSizeSlice.reducer