/*
 * Author: dizhong zhu
 * Date: 08/08/2024
 */
import {Location, matchRoutes, RouteObject} from "react-router-dom";
import routes from "./index";
import errorRoutes from "./error";

export type AppRouteConfig = RouteObject & {
    auth?: string
    restricted?: boolean
    name?: string
    children?: any
    settings?: any
    isolated?: boolean
    isError?: boolean
    showNav?: boolean
}

export const getCurrentRouteContext = (location: Location): AppRouteConfig => {
    const matches = matchRoutes(routes, location)!

    if (matches === null) {
        return errorRoutes[0]
    }

    return matches[0].route
}