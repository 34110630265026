import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getTransactionRequest, getTransactionStatsRequest, getVisitorStatsRequest} from 'services/analytics'
import {RootState} from 'store'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

interface TransactionStatT {
    name: string
}

interface TransactionT {
    name: string
}

interface PageViewT {
    name: string
}


interface AnalyticsState {
    transactionStat: TransactionStatT[]
    transactions: TransactionT[]
    pageViews: PageViewT[]
    isLoadingAnalytics: boolean
    analyticError: any[]
}

interface ListTransactionStatResponse {
    data: TransactionStatT[]
    error: any[]
    message: string
    status: boolean
}

interface ListTransactionResponse {
    data: TransactionT[]
    error: any[]
    message: string
    status: boolean
}

interface PageViewResponse {
    data: PageViewT[]
    error: any[]
    message: string
    status: boolean
}

export const initialState: AnalyticsState = {
    transactionStat: [],
    transactions: [],
    pageViews: [],
    isLoadingAnalytics: true,
    analyticError: [],
}

interface GetTransactionStatsArgs {
    domain: string;
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getTransactionStats = createAsyncThunk<TransactionStatT[], any>('analytics/list', async ({domain}, {dispatch}) => {
    dispatch(setIsLoadingAnalytics(true))

    return getTransactionStatsRequest(domain)
        .then((res: ListTransactionStatResponse) => {
            dispatch(setTransactionStat(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setAnalyticError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingAnalytics(false))) as Promise<TransactionStatT[]>
})

export const getTransactions = createAsyncThunk<TransactionT[], any>('transaction/list', async ({domain}, {dispatch}) => {
    dispatch(setIsLoadingAnalytics(true))

    return getTransactionRequest(domain)
        .then((res: ListTransactionResponse) => {
            dispatch(setTransactions(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setAnalyticError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingAnalytics(false))) as Promise<TransactionT[]>
})

export const getPageViews = createAsyncThunk<PageViewT[], any>('visitor/list', async ({domain}, {dispatch}) => {
    dispatch(setIsLoadingAnalytics(true))

    return getVisitorStatsRequest(domain)
        .then((res: PageViewResponse) => {
            dispatch(setPageViews(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setAnalyticError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoadingAnalytics(false))) as Promise<PageViewT[]>
})

export const analyticSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setTransactionStat: (state, {payload}: PayloadAction<TransactionStatT[]>) => {
            state.transactionStat = payload
        },
        setPageViews: (state, {payload}: PayloadAction<PageViewT[]>) => {
            state.pageViews = payload
        },
        setTransactions: (state, {payload}: PayloadAction<TransactionT[]>) => {
            state.transactions = payload
        },
        setAnalyticError: (state, {payload}: PayloadAction<any>) => {
            state.analyticError = payload
        },

        setIsLoadingAnalytics: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoadingAnalytics = payload
        },
    },
})

export const {
    setTransactionStat,
    setTransactions,
    setPageViews,
    setIsLoadingAnalytics, setAnalyticError
} = analyticSlice.actions

export const analyticSelector = (state: RootState) => state.tenant

export default analyticSlice.reducer
