/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

import {useEffect} from 'react'
import {Container, Card} from 'react-bootstrap'
import '../tenants.css'
import {Divider} from 'widgets/UtilWidgets'
import {useAppDispatch, useAppSelector} from 'store'
import {getTenant} from 'store/tenant'
import {getConfigurations} from 'store/configuration'
import {TenantInfo} from './TenSettingInfo'
import {WidgetConfiguration} from './TenSettingWidget'
import {DangerousZone} from './TenSettingDangerousZone'

interface props {
    domain: string;
}

const TenSettingGeneral: React.FC<props> = ({domain}) => {
    const dispatch = useAppDispatch()

    const {activeTenant} = useAppSelector((state) => state.tenant)
    const {configurations} = useAppSelector((state) => state.configuration)

    useEffect(() => {
        dispatch(getTenant(domain))
        dispatch(getConfigurations(domain))
    }, [])

    return (
        <Container fluid>
            <Card className='setting-boarder mb-4'>
                <Card.Body>
                    <h4>Tenant Information: {activeTenant?.domain}</h4>
                    <Divider className='my-2'/>
                    <TenantInfo info={activeTenant}/>

                    <h4 style={{marginTop: '50px'}}>Widget configurations</h4>
                    <Divider className='my-2'/>
                    <WidgetConfiguration domain={domain} configs={configurations}/>

                    <h4 style={{marginTop: '50px'}}>Dangerous</h4>
                    <Divider className='my-2'/>
                    <DangerousZone domain={domain}/>
                </Card.Body>
            </Card>
        </Container>
    )
}

export {TenSettingGeneral}
